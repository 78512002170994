import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter,
  Switch,
  Route,
  Routes,
  Router,
  HashRouter,
  Redirect,
  Navigate
} from "react-router-dom";
import FirstPage from "./Components/FirstPage";
import ForgetPass from "./Components/ForgetPass";
import LoginPage from "./Components/LoginPage";
import ImportCsvFilesForUsers from "./Components/ImportCsvFilesForUsers";
import AddEmail from "./Components/AddEmail";
import ManageEmails from "./Components/ManageEmails";
import ManagePoNumber from "./Components/ManagePoNumber";
import ViewAllOrder from "./Components/ViewAllOrder";
import Banner from "./Components/Banner";
import FirstPageNew from "./Components/FirstPageNew";
import ParticipantPointsHistory from "./Components/ParticipantPointsHistory";
import ParticipantOrderHistory from "./Components/ParticipantOrderHistory";
import { DropdownSubmenu, NavDropdownMenu} from "react-bootstrap-submenu";
import LoginPageTwo from "./Components/LoginPageTwo";
// import UserForm from "./Components/UserForm";
function App() {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<LoginPage/>} />
      <Route path="/FirstPageNew" element={<FirstPageNew/>} />
      <Route path="/ParticipantOrderHistory" element={<ParticipantOrderHistory/>} />
      <Route path="/ParticipantPointsHistory" element={<ParticipantPointsHistory/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
