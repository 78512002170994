import React from "react";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';


const Points = () =>{


    return (
        <>

<div className="container-fluid fpage">
<div className="row">
    <div className="col-md-5 fpage">
<img src="https://otb-demo.com/images/logo.png" className="logo fpage" />
    </div>  
    <div className="col-md-7 fpage">
<div className="cart">
    <span className="shop"><a href="#">Shoping Cart [99]</a></span>
    <span className="logout"><a href="/"> Logout</a> </span>
</div>
<ul className="pointsdetail">
    <li>97,403 <span className="pointsav">Available Points</span></li>
    <li>874,032 <span className="pointsav"> Points Earned</span></li>
    <li>776,629 <span className="pointsav"> Points Redeemed</span></li>
</ul>
</div>
</div>
</div>

        </>
)}

export default Points;