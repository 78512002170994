import React from "react";

const OrderHistoryData = () =>{
   

    return (
<>

<h1>Order History</h1>
<p>We're excited to bring you a revolutionary incentive program that is unparalled in the industary. We're excited to bring you a revolutionary incentive program that is unparalled in the industary!</p>

</>
)}

export default OrderHistoryData;