import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import 'antd/dist/antd.css';
import Menu from "./Menu";
import Points from "./Points";
import PointsTable from "./PointsTable";
import OrderHistoryData from "./OrderHistoryData";



const ParticipantOrderHistory = () =>{

    return (
<>

<Points/>
<Menu/>

<div className="container-fluid poh">

 <OrderHistoryData/>
 <PointsTable/>

</div>

  </>
)}

export default ParticipantOrderHistory;