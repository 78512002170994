import React from 'react';
import Header from './Header';
import Footer from './Footer';
import FirstPageNew from './FirstPageNew';
import { useNavigate } from 'react-router';
import LoginImage from './Login.gif';
import BestBy from './BestBy.png';
import headphones from './headphones.png';
import Login from './Login.gif';
// import { Icon } from '@iconify/react';
// import microphoneIcon from '@iconify/icons-fa-solid/microphone';
import { Link, Redirect,Navigate } from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import { Icon } from '@iconify/react';
import giftFill from '@iconify/icons-eva/gift-fill';
import FooterFirstPage from './FooterFirstPage';
import { DropdownSubmenu, NavDropdownMenu} from "react-bootstrap-submenu";
import background from "./Login.gif";
import appleplaystroe from "./appleplaystroe.png";
import AppStore from "./AppStore.svg";
import PlayStore from "./PlayStore.svg";
import out_of_the_box_logo_new from "./out_of_the_box_logo_new.png";
import googleplaystrore2 from "./googleplaystrore2.png";
import acclaimIcon from '@iconify/icons-simple-icons/acclaim';
import AppStoree from "./AppStoree.svg";





const LoginPage=()=> {
	const navigate = useNavigate();
	function handleClick() {
		navigate('/FirstPageNew')
	  }
	  




  return (
<>




<div className="bg loginpage">

<div className="boxes">
<div className="bkg">
<div className="container">
<Navbar  expand="lg">

    <Navbar.Brand href="#home"><img src="https://otb-demo.com/images/logo.png" className="logo"/></Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="ms-auto">
        <Nav.Link href="#home" className="catalog" style={{color:"blue !important"}}>Browse Catalog <Icon icon={acclaimIcon} rotate={1} className="svgicon" /></Nav.Link>
        <Nav.Link className="distr">
		
<div className="info">

	<img src={headphones} className="dimensions"/>


<div className="addresssection">
		<h2 className="question">Questions?</h2>
	<small><span className="email">help@eliterewards.biz</span></small>
	<small><span className="phone">(866) 354-8321</span></small>
	<small className="address" ><p>8:00am to 5:00pm EST, M-F. 8:00am to 5:00pm EST, M-F.</p></small>
	</div>
</div>
	
		</Nav.Link>

      </Nav>
    </Navbar.Collapse>

</Navbar>




<div className="central" >

<img src={Login} className="centralimages"/>
<div className="foram" >
<form action="/action_page.php" >
 
    <input type="text" id="fname" name="firstname" placeholder="Username"/>

    <input type="password" id="password" name="password" placeholder="Password"/>


  <div className="input-group checking">
  
    <input type="submit" value="Login" onClick={handleClick}/>
	<label className="remember">
	<input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" /> Remember Me
	</label>
	<a className="btn btn-link forgot">Forgot Username or Password?</a>
	</div>
  </form>
</div>




</div>


<div className="centraltext footerlogin">
	<a href="#" className="appstore"><img src={AppStoree} className="googleplay"/></a>
	<a href="#" className="googleplay"><img src={PlayStore}  className="googleplay"/></a>
</div>

</div>



</div>

<div className="footerbotm">
<p>© Copyright 2019. All rights reserved by Elite Rewards.</p>
<p>help@eliterewards.biz and 866-354-8321 8:00am to 5:00pm EST, M-F.</p>
</div>

</div>




</div>

</>
	);
}

export default LoginPage;
