import React, { useState } from "react";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import "react-datepicker/dist/react-datepicker.css";


const Menu = () =>{
    const [startDate, setStartDate] = useState(new Date());

    return (
        <>

<Navbar  expand="lg" className="navfpage">
    <Navbar.Brand href="#home" className="logofpage">Welcome, John Doe</Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav fpage">
      <Nav className="ms-auto fpagenav">
        <Nav.Link href="/FirstPageNew" className="fpagenav first">View Rewards</Nav.Link>
        <Nav.Link href="#home" className="fpagenav">My Account</Nav.Link>
        <Nav.Link href="/ParticipantPointsHistory" className="fpagenav">Point History</Nav.Link>
        <Nav.Link href="/ParticipantOrderHistory" className="fpagenav">Order History</Nav.Link>
        <Nav.Link href="#home" className="fpagenav">Shopping Cart [99]</Nav.Link>
        <Nav.Link href="/ParticipantDetails" className="fpagenav">Wish List</Nav.Link>
      </Nav>
    </Navbar.Collapse>
</Navbar>

        </>
)}

export default Menu;