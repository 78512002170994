import React from "react";
import 'antd/dist/antd.css';
import { Icon } from '@iconify/react';
import print20Filled from '@iconify/icons-fluent/print-20-filled';
import documentExport from '@iconify/icons-carbon/document-export';


import { DatePicker, Space } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;

const dateFormat = 'YYYY-MM-DD';








const PointsTable = () =>{


    return (
        <>

<div className="pointsdetails">


<div className="row">
    <div className="col-md-6 date poh">
<div className="row">
    <div className="col-md-12 date poh">

    <Space direction="vertical" size={12}>
    
    
    <RangePicker
      defaultValue={[moment('2015/01/01', dateFormat), moment('2015/01/01', dateFormat)]}
      format={dateFormat}
    />
  </Space>



    </div>
</div>
    </div>
   
    <div className="col-md-6 poh">
    <div className="row">
    
    <div className="col-md-10 poh">
    <a href="#">
        <span className="threemonth poh">3 mo</span>
    </a>
    <a href="#">
        <span className="sixmonth poh">6 mo</span>
        </a>
        <a href="#">
        <span className="tewlvemonth poh">12 mo</span>
        </a>
    </div>
    
    <div className="col-md-2 poh">
    <a href="#">
    <Icon icon={documentExport}  className="doc poh"/> </a>
    <a href="#"> <Icon icon={print20Filled} className="print poh" /> </a>
    
    
    </div>


    </div>
        </div>
</div>





<table className="table">
                        <thead className="poh">
                            <tr>
                             
                                <th>Transaction Description</th>
                                <th>Points</th>
                                <th>Point Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="tablerowcolor poh">
                              
                                <td>Product Purchase: Order # 3605778
                                <p className="tabledata">Wednessday, September 13, 2017 04:05PM</p>
                                </td>
                                <td>-50</td>
                                <td>97,403</td>
                            </tr>

                            <tr >
                               
                            <td>Product Purchase: Order # 3605778
                                <p className="tabledata">Monday, September 11, 2017:04:05PM</p>
                                </td>
                                <td>-1500</td>
                                <td>98,453</td>
                            </tr>
                            <tr className="tablerowcolor poh">
                            
                            <td><span className="refund">Point Refund: Order # 12345</span>
                                <p className="tabledata">Monday, September 04, 2017:04:05PM</p>
                                </td>
                                <td><span className="refund">500</span></td>
                                <td>99,953</td>
                            </tr>
                            <tr >
                     
                            <td>Product Purchase: Order # 17898
                                <p className="tabledata">Monday, September 04, 2017:04:05PM</p>
                                </td>
                                <td>-500</td>
                                <td>99,453</td>
                            </tr>
                            <tr className="tablerowcolor poh">
                            
                            <td>Product Purchase: Order # 12345
                                <p className="tabledata">Wednessday, September 13, 2017 04:05PM</p>
                                </td>
                                <td>-500</td>
                                <td>99,953</td>
                            </tr>
                            <tr >
                            
                            <td>Product Purchase: Order # 3605778
                                <p className="tabledata">Saturday October 05, 2017:11:12AM</p>
                                </td>
                                <td>-1,000</td>
                                <td>100,453</td>
                            </tr>
                            <tr className="tablerowcolor poh">
                            
                            <td>Product Purchase: Order # 3605778
                                <p className="tabledata">Friday, October 04, 2017:06:35PM</p>
                                </td>
                                <td>-10,000</td>
                                <td>110,453</td>
                            </tr>
                        </tbody>
                    </table>
                    <p className="tableline poh">~ These totals are estimates and may change</p>




</div>

        </>
)}

export default PointsTable;