import React from 'react';
 import Header from './Header';
 import Footer from './Footer';



function ForgetPass(){
  return (
<>

<div className="container">
 <Header/>
</div>
<div className="outer-wrapper">
<div className="container">
<div className="row">
<div className="col-md-8">
<div className="info-sec">
<h1 className="login-head">ACHIEVEMENT</h1>
<p>You have worked hard for your success.</p>
<p>Now relax and browse through items selected just for you.</p>
</div>
<div id="myModal" className="modal fade" role="dialog">
<div className="modal-dialog">
<div className="modal-content">
<div className="modal-header">
<button type="button" className="close" data-dismiss="modal">×</button>
<h4 className="modal-title">Tidewater Stars</h4>
</div>
<div className="modal-body">
<video width="100%" controls="">
<source src="video/tidewaterstar.mp4" type="video/mp4"/>Your browser does not support HTML5 video.</video>
</div>
</div>
</div>
</div>
</div>
<div className="col-md-4">
<form name="form1" enctype="multipart/form-data" method="post" onsubmit="return reg_user()">
							<div className="login-wrapper">
								<div className="login-inner">
								<h2>Recover Your Password</h2>
								<label style={{fontSize:"12px"}}>Please enter your account email address, we will send you, your username and password.</label>
									<span style={{color:"red"}} id="mail"></span><br/>
									<input type="email" placeholder="Email Address" id="mail-field" name="email"/>
									<input type="submit" class="button" name="update" id="recover" value="Recover Password"/>	
									<button class="button" type="button" onclick="window.location.href='index.php'">Go back</button>
								  </div> </div>
								</form>
</div>
</div>
</div>
</div>
<Footer/>

</>
  );
}

export default ForgetPass;
