import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import 'antd/dist/antd.css';
import Menu from "./Menu";
import Points from "./Points";
import ParticipantHistoryData from "./ParticipantHistoryData";
import PointsTable from "./PointsTable";



const ParticipantOrderHistory = () =>{

    return (
<>
<Points/>
<Menu/>
<div className="container-fluid poh">

<ParticipantHistoryData/>
<PointsTable/>

</div>

</>
)}

export default ParticipantOrderHistory;